import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
// import { MyPictureQuery } from "../../graphql-types";


export const pageQuery = graphql`
  query About {
    eva: file(relativePath: { eq: "eva.png" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;


const Page = () => {
  const data = useStaticQuery(pageQuery);// as MyPictureQuery;

  return (
  <Layout>
    <SEO title="about" />
    <div>
      <h1>Hello! I am Eva Tomlinson.</h1>
      <p> I am a student at Georgia Tech, and I am majoring in Computer Science.
      I am interested in learning new things and technology. I believe in being limitless.
      There is nothing that I can't do until I say myself that I can't do.</p>
      <p>I love reading and playing any kinds of sports specifically soccer, tennis, and Muay Thai(I won't hurt ya!).</p>
      <Img fixed={data.eva.childImageSharp.fixed} />
    </div>
  </Layout>
  );
};


export default Page;
